import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";

export function useUserAllProjects() {
  return useQuery(
    ["userAllProjects"],
    async () => {
      const { data } = await axios.get(
        "/api/projects/get-all-of-one-users-projects"
      );
      return data;
    },
    {
      staleTime: 120 * 60 * 1000, // 2hrs
      cacheTime: 120 * 60 * 1000,
      onError: (error) => {
        console.error("Error fetching user projects:", error);
      },
    }
  );
}

export const useProjectQuery = (slug) => {
  const queryClient = useQueryClient();

  const query = useQuery(
    ["project", slug],
    async () => {
      if (slug === null) return;
      const { data } = await axios.get(
        `/api/projects/get-project-by-slug/${slug}`
      );
      return data;
    },
    {
      enabled: !!slug, // Only fetch if slug is provided
      staleTime: 120 * 60 * 1000, // 2 hours
      cacheTime: 120 * 60 * 1000, // 2 hours
      onError: (error) => {
        console.error("Error fetching project data", error);
      },
    }
  );

  const refetchProject = query.refetch;

  const invalidateProject = () => {
    queryClient.invalidateQueries(["project", slug]);
  };

  return {
    ...query,
    refetchProject,
    invalidateProject,
  };
};

export function useBusinessProjects(businessId) {
  return useQuery(
    ["businessProjects", businessId],
    async () => {
      if (!businessId) return [];
      try {
        const { data: response } = await axios.get(
          `/api/v1/projects/get-business-projects/${businessId}`
        );
        
        return response?.data || [];
      } catch (error) {
        console.error('Error fetching business projects:', error);
        return [];
      }
    },
    {
      enabled: !!businessId,
      staleTime: 120 * 60 * 1000, // 2hrs
      cacheTime: 120 * 60 * 1000,
      onError: (error) => {
        console.error("Error fetching business projects:", error);
      },
    }
  );
}

export const useSaveOriginalPhotos = () => {
  const queryClient = useQueryClient();

  const saveOriginalPhotos = async (fileUrls, projectId, slug) => {
    try {
      const { data: uploadedImages } = await axios.post(
        "/api/v1/projects/save-image-to-db",
        {
          project: projectId,
          images: fileUrls,
          tags: "upload",
        }
      );

      // Update the project data in the React Query cache immediately
      queryClient.setQueryData(["project", slug], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          uploadedImages: [
            ...oldData.uploadedImages,
            ...uploadedImages,
          ].reverse(),
        };
      });

      // Invalidate the query to trigger a refetch for fresh data
      queryClient.invalidateQueries(["project", slug]);

      toast.success("Photos saved successfully");
    } catch (error) {
      console.error("Error saving photos:", error);
      toast.error("Error saving photos");
    }
  };

  return saveOriginalPhotos;
};
