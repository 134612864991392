import { useQuery, useQueryClient } from '@tanstack/react-query';
import { setCookie, getCookie, hasCookie } from 'cookies-next';
import axios from 'axios';
import { useMemo } from 'react';
import { useUserProfile } from './auth'; // assuming useUserProfile is in a separate file
import { toast } from 'react-toastify';


export const SELECTED_BUSINESS_KEY = 'selectedBusiness';
export const USER_BUSINESSES_KEY = 'userBusinesses';

export const useSelectedBusiness = () => {
  const queryClient = useQueryClient();

  const fetchBusinessData = async (businessId) => {
    try {
      const response = await axios.post(`/api/get-business-by-id`, {
        businessId: businessId
      });
      return response.data || null;
    } catch (err) {
      console.error('Error fetching business:', err);
      return null;
    }
  };

  const { data: selectedBusiness, refetch } = useQuery({
    queryKey: [SELECTED_BUSINESS_KEY],
    queryFn: async () => {
      if (!hasCookie("business")) return null;
      const businessFromCookie = JSON.parse(getCookie("business"));
      if (!businessFromCookie?._id) return null;
      
      const freshBusinessData = await fetchBusinessData(businessFromCookie._id);
      if (freshBusinessData) {
        setCookie("business", JSON.stringify(freshBusinessData));
        return freshBusinessData;
      }
      
      return businessFromCookie; // Fallback to cookie data if fetch fails
    },
    initialData: hasCookie("business") ? JSON.parse(getCookie("business")) : null,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const setSelectedBusiness = (business) => {
    if (business) {
      setCookie("business", JSON.stringify(business));
      queryClient.setQueryData([SELECTED_BUSINESS_KEY], business);
    } else {
      // If clearing the business
      setCookie("business", "", { maxAge: 0 });
      queryClient.setQueryData([SELECTED_BUSINESS_KEY], null);
    }
  };

  const refetchBusinessData = async () => {
    if (!selectedBusiness?._id) return null;
    
    // Get fresh data
    const freshData = await fetchBusinessData(selectedBusiness._id);
    
    if (freshData) {
      // Update both cookie and cache
      setCookie("business", JSON.stringify(freshData));
      queryClient.setQueryData([SELECTED_BUSINESS_KEY], freshData);
      return freshData;
    }
    return null;
  };

  const invalidateSelectedBusiness = async () => {
    queryClient.invalidateQueries([SELECTED_BUSINESS_KEY]);
    setCookie("business", "", { maxAge: 0 });
  };

  return { 
    selectedBusiness, 
    setSelectedBusiness, 
    invalidateSelectedBusiness,
    refetchBusinessData 
  };
};

export const useUserBusinesses = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [USER_BUSINESSES_KEY],
    queryFn: async () => {
      try {
        const response = await axios.get('/api/v1/get-all-user-businesses');
        
        // Handle both possible data structures consistently
        return response.data?.data || response.data || [];
      } catch (err) {
        console.error('Error fetching businesses:', err);
        return [];
      }
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  });

  return { 
    businesses: data || [],
    isLoading,
    error 
  };
};

export const useBusinessEmployees = (businessId, searchTerm = '', roleId = '') => {
  return useQuery(
    ['business-employees', businessId, searchTerm, roleId],
    async () => {
      const response = await fetch('/api/v1/get-employees-by-business', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          businessId,
          searchTerm,
          roleId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch employees');
      }

      const data = await response.json();
      return data.data;
    },
    {
      enabled: !!businessId,
    }
  );
};

export const useGetCurrentEmployeeByBusiness = (businessId) => {
  return useQuery(
    ['current-employee', businessId],
    async () => {
      try {
        const response = await axios.get(`/api/v1/business/get-current-employee-by-business/${businessId}`);
        return response.data.data;
      } catch (error) {
        console.error('Failed to fetch current employee:', error);
        throw new Error('Failed to fetch current employee');
      }
    },
    {
      enabled: !!businessId,
    }
  );
};

export const useSupplierActiveOrders = (businessId, status = null) => {
  return useQuery(
    ['supplier-active-orders', businessId, status],
    async () => {
      const url = status 
        ? `/api/v1/business/get-supplier-active-orders/${businessId}?status=${status}`
        : `/api/v1/business/get-supplier-active-orders/${businessId}`;
      
      const {data} = await axios.get(url);

      if (!data || !Array.isArray(data)) {
        throw new Error('Failed to fetch supplier active orders');
      }

      console.log("data", data)
      return data
    },
    {
      enabled: !!businessId,
      onError: (error) => {
        console.error('Query error:', error);
      },
      onSuccess: (data) => {
        console.log('Query success, data:', data);
      }
    }
  );
};


export const useSupplierActiveProposals = (businessId, status = null) => {
  return useQuery(
    ['active-proposals', businessId, status],
    async () => {
      if (!businessId) return [];
      const response = await axios.get(`/api/v1/business/get-active-proposals/${businessId}`, {
        params: { status }
      });
      return response.data;
    },
    {
      enabled: !!businessId,
      onError: (err) => {
        console.error('Error fetching proposals:', err);
        toast.error('Failed to load proposals');
      }
    }
  );
}

export const useContractorActiveOrders = (businessId, status = null) => {
  return useQuery(
    ['contractor-active-orders', businessId, status],
    async () => {
      const url = status 
        ? `/api/v1/business/get-contractor-active-orders/${businessId}?status=${status}`
        : `/api/v1/business/get-contractor-active-orders/${businessId}`;
      
      const {data} = await axios.get(url);

      if (!data || !Array.isArray(data)) {
        throw new Error('Failed to fetch contractor active orders');
      }

      console.log("data", data)
      return data
    },
    {
      enabled: !!businessId,
      onError: (error) => {
        console.error('Query error:', error);
      },
      onSuccess: (data) => {
        console.log('Query success, data:', data);
      }
    }
  );
};

export const getEmployeeObjsForUser = ()=> {
  return useQuery(
    ['employee-objs-for-logged-in-user'],
    async () => {
      const {data} = await axios.get('/api/v1/business/get-all-employee-objs-by-user');
      console.log("Employee Ids", data)
      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  )
}