// import { hotjar } from "react-hotjar";
import * as gtag from "../lib/gtag";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { DefaultSeo } from "next-seo";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import NextNProgress from "nextjs-progressbar";
import Providers from "../context/Providers";
import Layout from "../components/Misc/layout/Layout";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import "../styles/globals.scss";
import { colors } from "../styles/variables";
// import "../styles/oldStyles.scss"
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "devextreme/dist/css/dx.light.css";

function disableConsoleLogInProduction() {
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }
}

export default function App({ Component, pageProps }) {
  //Google analytics lib configuration with router. The other configuration already exists
  const router = useRouter();

  useEffect(() => {
    disableConsoleLogInProduction();
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  //   //Hotjar configuration
  //   let HJID = 2798532;
  //   let HJSV = 6;

  //   useEffect(() => {
  //     hotjar.initialize(HJID, HJSV);
  //   }, [HJID, HJSV]);

  //   <!-- Hotjar Tracking Code for https://eduzuka.com/ -->
  //   <script>
  //       (function(h,o,t,j,a,r){
  //           h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  //           h._hjSettings={hjid:2798532,hjsv:6};
  //           a=o.getElementsByTagName('head')[0];
  //           r=o.createElement('script');r.async=1;
  //           r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  //           a.appendChild(r);
  //       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  //   </script>

  // function setGoogleTags() {
  //   if (process.env.GOOGLE_TAGS_ENV === "production") {
  //     return {
  //       // __html:`
  //       // window.dataLayer = window.dataLayer || [];
  //       // function gtag(){dataLayer.push(arguments);}
  //       // gtag('js', new Date());

  //       // gtag('config', 'G-X355EXNFVF');
  //       // `

  //       __html: `
  //                 window.dataLayer = window.dataLayer || [];
  //                 function gtag(){dataLayer.push(arguments);}
  //                 gtag('js', new Date());
  //                 gtag('config', 'UA-212210606-1');
  //                 `,
  //     };
  //   }
  // }

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker.register("/sw.js").then(
          function (registration) {
            // Registration was successful
            console.log(
              "ServiceWorker registration successful with scope: ",
              registration.scope
            );
          },
          function (err) {
            // registration failed :(
            console.log("ServiceWorker registration failed: ", err);
          }
        );
      });
    }
  }, []);

  const seoConfig = useMemo(
    () => ({
      openGraph: {
        type: "website",
        locale: "en_US",
        url: "https://outdoorbuilders.com/",
        siteName: "Outdoor Builders",
      },
    }),
    []
  );

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
      // Enable debug mode in development
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") posthog.debug();
      },
    });

    const handleRouteChange = () => posthog?.capture("$pageview");

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="google-site-verification"
          content="lhWsmGHnDYZodFYwQPkWAV74fJ_h2tsW01SSujDdegY"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=10.0,"
        />
        {/* <script dangerouslySetInnerHTML={setGoogleTags()}></script> */}
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-X355EXNFVF"></script> */}

        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-212210606-1"
        ></script> */}
        <meta
          name="description"
          content="Outdoor Builders id a platform for contractors and homeowners to collaborate and fulfil the bets projects"
        />
      </Head>
      <DefaultSeo {...seoConfig} />
      <PostHogProvider client={posthog}>
        <Providers>
          <Layout>
            <ToastContainer />
            <NextNProgress color={colors.$primary} showOnShallow={true} />
            <Component {...pageProps} />
            <ReactQueryDevtools initialIsOpen={false} />
          </Layout>
        </Providers>
      </PostHogProvider>
    </>
  );
}
