import { useContext } from "react";
import { Context } from "../../../context";
import Image from "next/image";
import LayoutFillImg from "../LayoutFillImg/LayoutFillImg";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { BiMenuAltRight, BiUserPlus } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import { UseHeaderContext } from "../../../context/HeaderContext";
import { UseWindowContext } from "../../../context/WindowContext";
// import logo from "../../../public/logo.png";
// import flora_logo from "../../../public/flora_logo.png";
import outdoorbuilderslogo from "../../../public/outdoorbuilderslogo-removebg-preview.png";
import Container from "../container/Container";
import DashboardProfile from "../dashboardProfile/DashboardProfile";
import SideNavigation from "../../Dashboard/SideNavigation/SideNavigation";
import Button from "../button/Button";
import styles from "./header.module.scss";
import { useUserProfile } from "../../../hooks/auth";
import {
  useSelectedBusiness,
  useUserBusinesses,
} from "../../../hooks/business";
import { useBusinessProjects } from "../../../hooks/projects";
import { useSelectedProject } from "../../../hooks/selectedProject";
import ChatHeader from "@/components/Chat/chatHeader/ChatHeader";
import { setCookie, getCookie, hasCookie, deleteCookie } from "cookies-next";

export default function Header() {
  const {
    setMenuTopHeight,
    setHeaderHeight,
    hContainerMWidth,
    specialNavPath,
  } = UseHeaderContext();
  const [isScroll, setIsScroll] = useState(false);
  const router = useRouter();
  const headerRef = useRef(null);
  const menuTopRef = useRef(null);
  const overlayRef = useRef(null);
  const menusWrapperRef = useRef(null);
  const { windowWidth } = UseWindowContext();

  const { data: user, isLoading: userLoading } = useUserProfile();
  const { selectedBusiness, setSelectedBusiness } = useSelectedBusiness();
  const { businesses, isLoading: businessesLoading } = useUserBusinesses();
  const { data: businessProjects, isLoading: projectsLoading } =
    useBusinessProjects(selectedBusiness?._id);
  const { selectedProject, setSelectedProject } = useSelectedProject();
  const [businessOptions, setBusinessOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);

  // Transform businesses data when it changes
  useEffect(() => {
    if (!user || !businesses || userLoading || businessesLoading) return;

    const transformedBusinesses = [
      {
        value: "",
        label: "Select Business",
        role: "all",
      },
      ...businesses.map((business) => {
        const isAdmin =
          business.createdBy === user._id ||
          (Array.isArray(business.admins) &&
            business.admins.includes(user._id));
        return {
          value: business._id,
          label: business.name,
          role: isAdmin ? "admin" : "employee",
          slug: isAdmin
            ? `/business/project-management`
            : `/business/employee-project-management`,
          ...business,
        };
      }),
    ];

    setBusinessOptions(transformedBusinesses);

    // If there's a selected business in storage but not in state, set it
    if (
      selectedBusiness &&
      !businessOptions.find((b) => b.value === selectedBusiness._id)
    ) {
      const storedBusiness = transformedBusinesses.find(
        (b) => b.value === selectedBusiness._id
      );
      if (storedBusiness) {
        handleBusinessSelect(storedBusiness);
      }
    }
  }, [user, businesses, userLoading, businessesLoading]);

  // Update project options when business projects change
  useEffect(() => {

    if (!businessProjects || projectsLoading) {
      console.log("No projects or still loading");
      return;
    }

    const transformedProjects = [
      {
        value: "",
        label: "All Projects",
        businessId: null,
      },
      ...businessProjects.map((project) => ({
        value: project._id,
        label: project.name || project.title, // Fallback to title if name is not available
        businessId: project.businessId,
        ...project,
      })),
    ];

    // console.log("Transformed Projects:", transformedProjects);
    setProjectOptions(transformedProjects);

    // If there's a selected project in storage but not in state, set it
    if (
      selectedProject &&
      !projectOptions.find((p) => p.value === selectedProject.value)
    ) {
      const storedProject = transformedProjects.find(
        (p) => p.value === selectedProject.value
      );
      if (storedProject) {
        handleProjectSelect(storedProject);
      }
    }
  }, [selectedBusiness?._id, businessProjects, projectsLoading]);

  // Handle business selection
  const handleBusinessSelect = (business) => {

    if (business && business.value !== "") {
      setSelectedBusiness({
        _id: business.value,
        name: business.label,
        ...business,
      });
    } else {
      setSelectedBusiness(null);
      setSelectedProject(null);
    }
  };

  // Handle project selection
  const handleProjectSelect = (project) => {
    // console.log("Selecting project:", project);
    if (project && project.value !== "") {
      setSelectedProject(project);
    } else {
      setSelectedProject(null);
    }
  };

  // console.log("BUSINESSES", businesses);

  // get header height
  const getHeaderHeight = useCallback(() => {
    setHeaderHeight(headerRef.current.getBoundingClientRect().height);
    windowWidth < 992 &&
      setMenuTopHeight(menuTopRef.current.getBoundingClientRect().height);
  }, [setHeaderHeight, setMenuTopHeight, windowWidth]);
  // set header height
  useEffect(() => {
    getHeaderHeight();
  }, [getHeaderHeight]);

  // resize window
  const onResizeWindow = useCallback(() => {
    getHeaderHeight();
    if (router.pathname !== "/user/chat") {
      windowWidth < 992 ? menuToggler() : menuToggler("OPEN", "noOverlay");
    } else {
      return;
    }
  }, [getHeaderHeight, windowWidth, router.pathname]);
  // invoked
  useEffect(() => {
    onResizeWindow();
    // invoked window width --resize
    window.addEventListener("resize", onResizeWindow);
    return () => window.removeEventListener("resize", onResizeWindow);
  }, [onResizeWindow]);

  // active menu tab
  function menuActiveTab(menu) {
    if (router.asPath === menu) {
      return styles.active;
    } else if (router.asPath === "/" && menu.toLowerCase() === "home") {
      return styles.active;
    } else if (
      router.asPath ===
      "/" + menu.toLowerCase().split(" ").join("-")
    ) {
      return styles.active;
    } else if (router.asPath == menu.split("/").includes("business")) {
      return styles.active;
    }
  }

  useEffect(() => {
    // on scroll
    function onScroll() {
      window.scrollY > 80 ? setIsScroll(true) : setIsScroll(false);
    }
    // invoked on scroll
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // on menu click
  const menuToggler = (type, overType) => {
    const overlay = overlayRef.current;
    const menu = menusWrapperRef.current;

    if (type === "OPEN") {
      if (overType !== "noOverlay") {
        overlay.style.opacity = 1;
        overlay.style.pointerEvents = "all";
      }
      menu.style.transform = `translateX(0)`;
    } else {
      menu.style.transform = `translateX(100%)`;
      overlay.style.opacity = 0;
      overlay.style.pointerEvents = "none";
    }
  };

  const routeChangeHandler = useCallback(() => {
    windowWidth < 992 && menuToggler();
  }, [windowWidth]);

  // router change
  useEffect(() => {
    router.events.on("routeChangeComplete", routeChangeHandler);
    return () => router.events.off("routeChangeComplete", routeChangeHandler);
  }, [routeChangeHandler, router.events]);

  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const [isProjectOpen, setIsProjectOpen] = useState(false);

  return (
    <>
      {router.pathname === specialNavPath && specialNavPath.includes("chat") ? (
        <ChatHeader />
      ) : (
        <header
          className={`${styles.header}${isScroll ? " " + styles.scroll : ""}`}
          ref={headerRef}
        >
          <Container
            style={
              router.pathname === specialNavPath
                ? { maxWidth: hContainerMWidth }
                : {}
            }
          >
            <nav className={styles.nav}>
              <div className={styles.logo}>
                <Link href={router.pathname.includes("business") ? "/business/dashboard" : "/"}>
                  <Image
                    src={outdoorbuilderslogo}
                    alt="Outdoor builders AI logo"
                    width={100}
                    height={38}
                  />
                </Link>
              </div>
              {windowWidth < 992 && (
                <button
                  type="button"
                  className={styles.menuBar}
                  onClick={() => menuToggler("OPEN")}
                >
                  <BiMenuAltRight />
                </button>
              )}
              <div
                className={styles.overlay}
                ref={overlayRef}
                onClick={menuToggler}
              />
              <div className={styles.menusWrapper} ref={menusWrapperRef}>
                {windowWidth < 992 && (
                  <div className={styles.menuTopBar} ref={menuTopRef}>
                    <div className={styles.logo}>
                      <Link href="/">
                        <Image
                          src={outdoorbuilderslogo}
                          alt="Outdoor builders AI logo"
                          width={100}
                          height={38}
                        />
                      </Link>
                    </div>
                    <button className={styles.menuClose} onClick={menuToggler}>
                      <MdOutlineClose />
                    </button>
                  </div>
                )}
                {router.pathname === specialNavPath &&
                specialNavPath == "/dashboard" &&
                windowWidth < 992 ? (
                  <SideNavigation isTitle={false} className="between" />
                ) : (
                  <ul className={styles.menus}>
                    <>
                      {/* {!user &&
                        !router.pathname.split("/").includes("business") ===
                          false && (
                          <li>
                            <Link href="/free-trial">
                              <span className={menuActiveTab("/free-trail")}>
                                Free Trial
                              </span>
                            </Link>
                          </li>
                        )} */}

                      {user && (
                        <>
                          <li></li>
                          {router.pathname.split("/").includes("business") && businessOptions.length > 1 && (
                            <>
                              <div
                                className={styles.header__content__selectors}
                              >
                                <select
                                  value={selectedBusiness?._id || ""}
                                  onChange={(e) => {
                                    const business = businessOptions.find(
                                      (b) => b.value === e.target.value
                                    );
                                    handleBusinessSelect(business);
                                  }}
                                  className={styles.header__content__selector}
                                >
                                  {businessOptions.map((business) => (
                                    <option
                                      key={business.value}
                                      value={business.value}
                                    >
                                      {business.label}
                                    </option>
                                  ))}
                                </select>

                                <select
                                  value={selectedProject?.value || ""}
                                  onChange={(e) => {
                                    const project = projectOptions.find(
                                      (p) => p.value === e.target.value
                                    );
                                    handleProjectSelect(project);
                                  }}
                                  className={styles.header__content__selector}
                                  disabled={
                                    !selectedBusiness || !selectedBusiness._id
                                  }
                                >
                                  {projectOptions.map((project) => (
                                    <option
                                      key={project.value}
                                      value={project.value}
                                    >
                                      {project.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </>
                          )}

                          {!router.pathname.split("/").includes("business") && (
                            <>
                              <li>
                                <Link
                                  href="/"
                                  className={menuActiveTab("home")}
                                >
                                  <span>Home</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  href="/user/projects"
                                  className={menuActiveTab("user/projects")}
                                >
                                  <span>Projects</span>
                                </Link>
                              </li>
                            </>
                          )}
                          <li>
                            {/* <Link
                              href="/user/chat"
                              className={menuActiveTab("user/chat")}
                            >
                              <span>Messages</span>
                            </Link> */}
                          </li>
                          <li>
                            <Link
                              href={router.pathname.includes("business") ? "/business/gallery" : "/gallery"}
                              className={menuActiveTab("gallery")}
                            >
                              <span>Gallery</span>
                            </Link>
                          </li>

                          {router.pathname.split("/").includes("business") ? (
                          <Link href="/business/credits/ai">
                            <span>
                              <strong>
                                {selectedBusiness &&selectedBusiness?.remainingImagegenerationCredits} credits
                              </strong>
                            </span>
                          </Link>
                        ) : ( <Link href="/credits">
                          <span>
                            <strong>
                              {user.remainingImagegenerationCredits} credits
                            </strong>
                          </span>
                        </Link>)}
                        </>
                      )}
                      {(router.pathname || router.asPath) == specialNavPath ||
                      user ? (
                        <li className={styles.dashboardProfileLink}>
                          <DashboardProfile />
                        </li>
                      ) : (
                        <ul className={styles.buttonsDiv}>
                          <li className={styles.menusBtnLink}>
                            <Link href="/business">
                              <div className={menuActiveTab("/auth/business")}>
                                <Button
                                  variant={"secondary"}
                                  // onClick={() => router.push("/auth/business")}
                                >
                                  Business
                                </Button>
                              </div>
                            </Link>
                          </li>
                          <li className={styles.menusBtnLink}>
                            <Link href="/auth">
                              <div className={menuActiveTab("/auth")}>
                                <Button
                                  variant={"cta"}
                                  // onClick={() => router.push("/auth")}
                                >
                                  Login
                                </Button>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </>
                  </ul>
                )}
              </div>
            </nav>{" "}
          </Container>
        </header>
      )}
    </>
  );
}
