import { useQuery, useQueryClient } from '@tanstack/react-query';
import { setCookie, getCookie, hasCookie } from 'cookies-next';

export const SELECTED_PROJECT_KEY = 'selectedProject';

export const useSelectedProject = () => {
  const queryClient = useQueryClient();

  const { data: selectedProject } = useQuery({
    queryKey: [SELECTED_PROJECT_KEY],
    initialData: hasCookie("project") ? JSON.parse(getCookie("project")) : null,
    staleTime: Infinity,
  });

  const setSelectedProject = (project) => {
    if (project) {
      setCookie("project", JSON.stringify(project));
    } else {
      setCookie("project", null);
    }
    queryClient.setQueryData([SELECTED_PROJECT_KEY], project);
  };

  return { selectedProject, setSelectedProject };
};
